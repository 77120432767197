import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h2>What we do</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Jasa pembuatan website desa.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Jasa pembuatan website pribadi.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Jasa pembuatan website usaha.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Jasa pembuatan website organisasi / komunitas</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Dll.</p>
            </li>
          </ul>
        </article>
        {/* END OF UI/UX */}
        <article className="service">
          <div className="service__head">
            <h3>Graphic Design</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Jasa pembuatan logo bisnis, komunitas, dan logo lainnya</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Jasa desain jersey komunitas, tim sepak bola / Futsal</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Jasa Desain Branding</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Jasa desain template sosial media</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Dll.</p>
            </li>
          </ul>
        </article>
        {/* WEB DEVELOPMENT */}
        <article className="service">
          <div className="service__head">
            <h3>Digital Printing</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Cetak kaos & jersey, pengerjaan manual dan printing sesuai kebutuhan</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Promosi, seperti banner, brosur, dan kebutuhan promosi lainnya.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Stationary, seperti book cover, letterhead, envelope, dan lainnya.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Dll.</p>
            </li>
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}
      </div>
    </section>
  )
}

export default Services