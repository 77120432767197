import React from 'react'
import './footer.css'
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {IoLogoYoutube} from 'react-icons/io'

const Footer = () => {
  return (
    <footer>
      <a href="https://ewakode.com" className='footer__logo'>Ewakode.com</a>
      <ul className='permalinks'>
      <small>Made with &hearts; and &#9749; by <a href="https://ewakode.com">Muslimin Syam</a></small>        
      </ul>

      <div className="footer__socials">
        <a href="https://facebook.com/cimynd.partii" target="_blank"><FaFacebookF/></a>
        <a href="https://instagram.com/vectoro.std" target="_blank"><FiInstagram/></a>
        <a href="https://www.youtube.com/@VectoroStd" target="_blank"><IoLogoYoutube/></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; 2023<a href="https://ewakode.com"> Ewakode.com</a>. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer