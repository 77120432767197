import React from 'react'
import './about.css'
import ME from '../../assets/tutu.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h2>Who am I</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>9+ Years Working</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>20+ Worldwide</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>90+ Completed</small>
            </article>
          </div>

          <p>
          Muslimin Syam, akrabnya dipanggil D'Tutu. Lulusan Manajemen Informatika dari salah satu Universitas ternama Di Makassar. Berpengalaman 9+ tahun dalam dunia desain grafis dan 3+ tahun bidang programing. Expert dalam menggunakan beragam software seperti Adobe Illustrator, CorelDRAW, Adobe Photoshop, serta mampu membangun sistem informasi menggunakan beberapa bahasa program seperti html, CSS, PHP, Javascript, dan bahasa program lainnya.
Seseorang yang sangat ambisius, teliti, dan terdorong untuk membuat inovasi menarik bagi perusahaan.
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About