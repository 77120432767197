import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/bilanrengi.jpg'
import IMG2 from '../../assets/jerseylaskar.png'
import IMG3 from '../../assets/enka.jpg'
import IMG4 from '../../assets/dental-logo.jpg'
import IMG5 from '../../assets/taxi.jpg'
import IMG6 from '../../assets/price-table.jpg'

// DO NOT USE THE IMAGES IN PRODUCTION

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Website resmi Pemerintah Desa Bilanrengi, Gowa - Indonesia',
    demo: 'https://bilanrengi.my.id'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Desain Logo dan Jersey Laskar Bontobiraeng FC',
    demo: 'https://https://www.instagram.com/official.laskarfc'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Desain Logo untuk Enka Agroniaga, Makassar - Indonesia',
    demo: 'https://facebook.com/enkaagroniaga'
  },
  {
    id: 4,
    image: IMG4,
    title: 'Personal project for microstock agency',
    demo: 'https://www.shutterstock.com/g/ciminkart'
  },
  {
    id: 5,
    image: IMG5,
    title: 'Personal project - Isometric taxi design for microstock agency',
    demo: 'https://www.shutterstock.com/g/fadhlicreation'
  },
  {
    id: 6,
    image: IMG6,
    title: 'Website subscription price table design for microstock agency',
    demo: 'https://www.shutterstock.com/g/fadhlicreation'
  }
]


const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h2>Our latest work</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, demo}) => {
            return (
              <article key={id} className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
              </div>
            </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio