import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/kades.jpg'
import AVTR2 from '../../assets/laskarfc.png'
import AVTR3 from '../../assets/aydee.jpg'
import AVTR4 from '../../assets/laplaca.jpg'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



const data = [
  {
    avatar: AVTR1,
    name: 'Syamsul Bahri',
    review: 'Dari ewakode.com kami bisa memiliki website yang sangat luar biasa sehingga kami bisa memberikan informasi publik yang lengkap mulai dari statistik penduduk, keuangan atau anggaran, status desa, sampai progres pembangunan desa'
  },
  {
    avatar: AVTR2,
    name: 'Laskar FC',
    review: 'Desain logo, jersey sampai branding club yang istimewa, modern, dan sangat luar biasa membuat club kami lebih dikenal umum'
  },
  {
    avatar: AVTR3,
    name: 'Aydee',
    review: 'You did amazing!!! I love it!!! Please send me all 3 illustrators files. I send you the rest of your payment soon! then I will send a brief for the next project'
  },
  {
    avatar: AVTR4,
    name: 'Jlaplaca',
    review: 'This looks amazing! I will send you the full payment shortly. Then you can send files over. Thank you very much!'
  },
]


const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h2>What they say</h2>

      <Swiper className="container testimonials__container" 
      // install Swiper modules
      modules={[Pagination]} spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}>
        {
          data.map(({avatar, name, review}, index) => {
            return (
              <SwiperSlide key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar}/>
              </div>
              <h5 className='client__name'>{name}</h5>
              <small className='client__review'>{review}</small>
            </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials